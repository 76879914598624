<template>
	<div class="home">
		<div class="main">
			<TOP title="葫芦娃2" :isSearch="true" history="/"></TOP>
			<!--快捷按钮-->
			<div class="kuangjieBtns">
				<router-link :to="{name:'luntang'}" replace> 
					<div class="kuangjieItem">
						<img src="@/assets/images/home_luntan_icon.png" />
						<span>社区论坛</span>
					</div>
				</router-link>
				<router-link :to="{name:'gongnue'}" replace> 
					<div class="kuangjieItem">
						<img src="@/assets/images/home_gongnue_icon.png" />
						<span>游戏攻略</span>
					</div>
				</router-link>
				<div class="kuangjieItem" v-if="false">
					<img src="@/assets/images/home_qiandao_icon.png" />
					<span>签到福利</span>
				</div>
			</div>

			<!--公告-->			
			<TIELIST from="home_top" history="/" ></TIELIST>
			
			<!--内容帖-->
			<TIELIST from="home_content" history="/" ></TIELIST>
			
			<!--发布按钮-->
			<PUBLISHBTN from="/"></PUBLISHBTN>
			<!--底部菜单-->
			<MENUE current='home'></MENUE>
			
		</div>		
		<!-- 开启顶部安全区适配 -->
		<!-- <van-nav-bar safe-area-inset-top /> -->
		
		<!-- 开启底部安全区适配 -->
		<!-- <van-number-keyboard safe-area-inset-bottom /> -->
	</div>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	
	import TOP from '@/components/top/top.vue';
	import TIELIST from '@/components/tie/list.vue';
	import MENUE from '@/components/menue/menue.vue';	
	import PUBLISHBTN from '@/components/publish/pushlistBtn.vue';
	// import { NavBar,NumberKeyboard } from 'vant';
	import { mapGetters } from 'vuex';
	import request from '@/service/request'
	import {Toast} from "vant"
	export default defineComponent({
		name: 'Index',
		components: {
			TOP: TOP,
			TIELIST: TIELIST,
			MENUE:MENUE,
			PUBLISHBTN,
			// [NavBar.name]:NavBar,
			// [NumberKeyboard.name]:NumberKeyboard
		},
		data(){
			return{
				guangFangList:[]
			}
		},
		mounted() {
			(document).querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #EDEDD0, #DBEFE7);");
			(document).title="葫芦娃2手游";
			//处理浏览器后台问题
			
		},
		unmounted() {
			(document).querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		},
		computed: {
		    ...mapGetters(['token'])
		},
		created(){			
			var token=localStorage.getItem('token');
			this.$store.dispatch('setToken',token);
		},
		methods:{
			
			//获取数据
			async getData(){
				console.log("")
			},
		}
	});
</script>

<style lang="less" scoped="scoped">
	.main {
		padding: 0.25rem 0.2rem;
		position: fixed;
		top:0;
		left: 0;
		right:0;
		bottom:0;
		overflow-y: auto;
		.kuangjieBtns {
			display: flex;
			margin-bottom: 0.24rem;
		}

		.kuangjieItem {
			width: 1.5rem;
			color: #5D6262;
			font-size: 0.25rem;
			display: flex;
			flex-direction: column;
			margin-left: 0.12rem;
			img {
				max-width:1.03rem;
				margin-bottom: 0.22rem;
			}
		}

		
	}
</style>
